export const RoundCorner = () => (
  <svg width={13} height={13} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07 6.837C6.242 7.603 5.5 9.187 5.5 13h-5c0-4.187.759-7.603 3.18-9.837C6.05.973 9.415.5 13 .5v5c-3.416 0-5.051.527-5.93 1.337z"
      fill="currentColor"
    />
  </svg>
);

export const SquareCorner = () => (
  <svg width={13} height={13} viewBox="0 0 13 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 0.5H13V5.5H5.5V13H0.5V0.5Z"
      fill="currentColor"
    />
  </svg>
);

export const Github = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37 0 0 5.37 0 12C0 17.31 3.435 21.795 8.205 23.385C8.805 23.49 9.03 23.13 9.03 22.815C9.03 22.53 9.015 21.585 9.015 20.58C6 21.135 5.22 19.845 4.98 19.17C4.845 18.825 4.26 17.76 3.75 17.475C3.33 17.25 2.73 16.695 3.735 16.68C4.68 16.665 5.355 17.55 5.58 17.91C6.66 19.725 8.385 19.215 9.075 18.9C9.18 18.12 9.495 17.595 9.84 17.295C7.17 16.995 4.38 15.96 4.38 11.37C4.38 10.065 4.845 8.985 5.61 8.145C5.49 7.845 5.07 6.615 5.73 4.965C5.73 4.965 6.735 4.65 9.03 6.195C9.99 5.925 11.01 5.79 12.03 5.79C13.05 5.79 14.07 5.925 15.03 6.195C17.325 4.635 18.33 4.965 18.33 4.965C18.99 6.615 18.57 7.845 18.45 8.145C19.215 8.985 19.68 10.05 19.68 11.37C19.68 15.975 16.875 16.995 14.205 17.295C14.64 17.67 15.015 18.39 15.015 19.515C15.015 21.12 15 22.41 15 22.815C15 23.13 15.225 23.505 15.825 23.385C18.2072 22.5808 20.2773 21.0498 21.7438 19.0074C23.2103 16.9651 23.9994 14.5143 24 12C24 5.37 18.63 0 12 0Z"
      fill="currentColor"
    />
  </svg>
);

export const About = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 21 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7731 39.9131H13.2733V16.1633C13.2733 14.6632 12.0233 13.4132 10.5233 13.4132H10.4232H10.3231H5.32311C3.82304 13.4132 2.57305 14.6632 2.57305 16.1633C2.57305 17.6633 3.82304 18.9133 5.32311 18.9133H7.82309V39.9132H3.32331C1.82323 39.9132 0.573242 41.1631 0.573242 42.6632C0.573242 44.1633 1.82323 45.4133 3.32331 45.4133H17.8232C19.3233 45.4133 20.5732 44.1633 20.5732 42.6632C20.5732 41.1631 19.3233 39.9132 17.7734 39.9132L17.7731 39.9131Z"
      fill="currentColor"
    />
    <path
      d="M13.4731 5.11332C13.4731 7.48812 11.5702 9.41328 9.22293 9.41328C6.87572 9.41328 4.97314 7.48812 4.97314 5.11332C4.97314 2.73852 6.87572 0.813354 9.22293 0.813354C11.5701 0.813354 13.4731 2.73852 13.4731 5.11332Z"
      fill="currentColor"
    />
  </svg>
);
